<template>
  <div id="AwardWelcome" v-if="FormatAwards != undefined || FormatAwards != null">
    <div class="titleDiv">
      <div class="display-2 px-5">Awards</div>
    </div>

    <v-container fluid>
      <v-row height="100%" class="award-container" v-show="FormatAwards">
        <v-col
          cols="12"
          sm="12"
          md="3"
          v-for="(data, index) in FormatAwards"
          :key="index"
          height="100%"
        >
          <v-fade-transition>
            <v-card class="elevation-5 grey lighten-3 animated fadeIn slower" height="100%">
              <v-card-title>{{data.month}} - {{data.year}}</v-card-title>

              <div
                class="d-flex flex-column align-center justify-center"
                v-if="data.studentData.imageUrl != null"
              >
                <v-img
                  class="animated elevation-5 std-image fadeIn slower"
                  aspect-ratio="1"
                  :src="data.studentData.imageUrl"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular :size="80" :width="15" color="purple" indeterminate></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div class="d-flex flex-column align-center justify-center" v-else>
                <v-img
                  class="animated elevation-5 std-image fadeIn slower"
                  aspect-ratio="1"
                  src="../../assets/images/profile-pic.svg"
                >
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular :size="80" :width="15" color="purple" indeterminate></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <v-card-text class="text-center my-3 text--primary">
                <p class="title">{{data.studentData.name}}</p>
                <div
                  class="d-flex align-center subtitle-1 text-center mb-5 justify-center flex-column"
                >{{data.award.title}}</div>
              </v-card-text>
            </v-card>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>


<script>
export default {
  name: "AwardWelcome",

  data() {
    return {
      months: [
        { name: "June", value: 6 },
        { name: "July", value: 7 },
        { name: "August", value: 8 },
        { name: "September", value: 9 },
        { name: "October", value: 10 },
        { name: "November", value: 11 },
        { name: "December", value: 12 },
        { name: "January", value: 1 },
        { name: "February", value: 2 }
      ]
    };
  },

  computed: {
    Quarter() {
      return this.$route.params.quarter != null
        ? this.$route.params.quarter
        : null;
    },

    // AwardData() {
    //   if (this.Quarter != null) {
    //     let data = this.$store.getters.GetStudentAwardWithQuarter(this.Quarter);
    //     return data.length != 0
    //       ? data.sort(function(a, b) {
    //           return parseFloat(a.month) - parseFloat(b.month);
    //         })
    //       : null;
    //   } else {
    //     return this.$router.push("/");
    //   }
    // },

    AwardData() {
      if (this.Quarter != null) {
        let data = this.$store.getters.GetStudentAwardWithMonth(this.Quarter);
        return data.length != 0
          ? data.sort(function(a, b) {
              return parseFloat(a.month) - parseFloat(b.month);
            })
          : null;
      } else {
        return this.$router.push("/");
      }
    },

    GetAllAwards() {
      let data = this.$store.getters.GetAllAwards;
      return data != "" ? data : null;
    },

    FormatAwards() {
      if (this.AwardData != null) {
        let TmpData = [];
        this.AwardData.map(ad => {
          // get award data based on slug
          let data = this.$store.getters.GetAwardWithSlug(ad.awardslug);
          let award = data.length != 0 ? data[0] : data;
          // get month based on number
          let data2 = this.months.filter(m => m.value == ad.month);
          let monthname = data2.length != 0 ? data2[0].name : data;
          // get studentdata based on id
          let data3 = this.$store.getters.GetStudentWithID(ad.studentID);
          let studentData = data3.length != 0 ? data3[0] : data;
          TmpData.push({
            studentData: studentData,
            awardslug: ad.awardslug,
            month: monthname,
            award: award,
            year: ad.year
          });
        });
        // sort per year
        return TmpData.length != 0
          ? TmpData.sort(function(a, b) {
              return parseFloat(b.year) - parseFloat(a.year);
            })
          : null;
      }
    }
  }
};
</script>

<style scoped>
#AwardWelcome {
  padding: 3vw;
  padding-top: 2rem !important;
  color: #ddd;
}
.titleDiv {
  border-left: 10px solid #ddd;
  margin-bottom: 2rem;
  color: #ddd;
}
.award-container {
  padding-left: 1vw;
  padding-right: 1vw;
}
.std-image {
  border-radius: 100px !important;
  height: 150px !important;
  width: 150px !important;
}
</style>